<template>
	<div class="layout-navbars-breadcrumb-index">
		<img src="@/assets/imgs/headerImg.png" alt="" />
		<p>基层卫生健康智慧服务平台-数据中台</p>
		<User />
	</div>
</template>

<script>
import User from '@/layout/navBars/breadcrumb/user.vue';
export default {
	name: 'layoutNavBars',
	components: { User },
	data() {
		return {
			menuList: [],
		};
	},
	computed: {
		// 设置是否显示横向菜单
		isLayoutTransverse() {
			let { layout, isClassicSplitMenu } = this.$store.state.themeConfig.themeConfig;
			return layout === 'transverse' || (isClassicSplitMenu && layout === 'classic');
		},
	},
	mounted() {
		this.setFilterRoutes();
	},
	methods: {
		// 设置路由的过滤
		setFilterRoutes() {
			this.menuList = this.filterRoutesFun(this.$store.state.routesList.routesList);
		},
		// 设置路由的过滤递归函数
		filterRoutesFun(arr) {
			return arr
				.filter((item) => !item.meta.isHide)
				.map((item) => {
					item = Object.assign({}, item);
					if (item.children) item.children = this.filterRoutesFun(item.children);
					return item;
				});
		},
	},
	watch: {
		// 监听 vuex 数据变化
		'$store.state': {
			handler(val) {
				if (val.routesList.routesList.length === this.menuList.length) return false;
				this.setFilterRoutes();
			},
			deep: true,
		},
	},
};
</script>

<style scoped lang="scss">
.layout-navbars-breadcrumb-index {
	height: 48px;
	display: flex;
	align-items: center;
	padding-right: 30px;
	overflow: hidden;
	background: var(--prev-color-primary);
	// border-bottom: 1px solid var(--prev-border-color-lighter);
	// box-sizing: border-box;
	.title {
		display: flex;
		align-items: center;
	}
	img {
		// padding-left: 30px;
		margin-left: 24px;
		width: 26px;
		height: 26px;
		margin-right: 10px;
	}
	p {
		font-size: 16px;
		color: #fff;
		font-weight: bold;
	}
}
</style>
