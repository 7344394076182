<template>
	<div class="layout-navbars-breadcrumb-user" :style="{ flex: layoutUserFlexNum }">
		<div class="flex">
			<div class="navbar-user">
				<p>{{ currentTime }}！{{ userName }} {{ currentHour }}</p>
				<router-link class="header" to="/account/personalInfo">
					<img src="@/assets/imgs/avactor.png" alt="" />
					<span>超级管理员</span></router-link
				>
			</div>
			|<span class="logout" @click="loginOut">退出登录</span>
		</div>
	</div>
</template>

<script>
import { formatDate, formatAxis } from '@/utils/formatTime';
import { Session, Local } from '@/utils/storage.js';
export default {
	name: 'layoutBreadcrumbUser',

	data() {
		return {
			disabledSize: '',
		};
	},
	computed: {
		// 设置弹性盒子布局 flex
		layoutUserFlexNum() {
			let { layout, isClassicSplitMenu } = this.$store.state.themeConfig.themeConfig;
			let num = '';
			if (layout === 'defaults' || (layout === 'classic' && !isClassicSplitMenu) || layout === 'columns') num = 1;
			else num = null;
			return num;
		},
		// 获取当前时间
		currentTime() {
			return formatAxis(new Date());
		},
		currentHour() {
			return formatDate(new Date(), 'HH:MM');
		},
		userName() {
			return Session.get('userInfo').name;
		},
	},
	mounted() {
		if (Local.get('themeConfigPrev')) {
			this.initComponentSize();
		}
	},
	methods: {
		// 初始化全局组件大小
		initComponentSize() {
			switch (Local.get('themeConfigPrev').globalComponentSize) {
				case '':
					this.disabledSize = '';
					break;
				case 'medium':
					this.disabledSize = 'medium';
					break;
				case 'small':
					this.disabledSize = 'small';
					break;
				case 'mini':
					this.disabledSize = 'mini';
					break;
			}
		},
		// 退出
		loginOut() {
			setTimeout(() => {
				this.$msgbox({
					closeOnClickModal: false,
					closeOnPressEscape: false,
					title: '提示',
					message: '此操作将退出登录, 是否继续?',
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '退出中...';
							setTimeout(() => {
								done();
								setTimeout(() => {
									instance.confirmButtonLoading = false;
								}, 300);
							}, 700);
						} else {
							done();
						}
					},
				})
					.then(() => {
						// 清除缓存/token等
						Session.clear();
						Local.clear();
						// 使用 reload 时，不需要调用 resetRoute() 重置路由
						window.location.reload();
						// let id = Session.get('userInfo').id;
						// this.$http.delete(this.api.login + `/${id}.json`).then((res) => {
						// 	if (res.data && res.data.success) {
						// 	}
						// });
					})
					.catch(() => {});
			}, 150);
		},
	},
};
</script>

<style scoped lang="scss">
.layout-navbars-breadcrumb-user {
	color: var(--prev-bg-topBar);
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	&-link {
		height: 100%;
		display: flex;
		align-items: center;
		white-space: nowrap;
		&-photo {
			width: 25px;
			height: 25px;
			border-radius: 100%;
		}
	}
	&-icon {
		padding: 0 10px;
		cursor: pointer;
		height: 50px;
		line-height: 50px;
		display: flex;
		align-items: center;
		&:hover {
			background: var(--prev-color-hover);
			i {
				display: inline-block;
				animation: logoAnimation 0.3s ease-in-out;
			}
		}
	}
	& ::v-deep .el-dropdown {
		color: var(--prev-bg-topBar);
	}
	& ::v-deep .el-badge {
		height: 40px;
		line-height: 40px;
		display: flex;
		align-items: center;
	}
	& ::v-deep .el-badge__content.is-fixed {
		top: 12px;
	}
	& ::v-deep .el-button {
		color: #fff;
		line-height: 22px;
		padding: 0 15px;
		margin-left: 0;
	}
	.modifyPwd {
		border-right: 1px solid #fff;
		border-radius: 0;
	}
	.flex {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.navbar-user {
		display: flex;
		justify-content: center;
		align-items: center;
		p {
			margin-right: 16px;
		}
		.header {
			margin-right: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			text-decoration: none;
			color: #fff;
		}
		img {
			margin-right: 8px;
			width: 24px;
			height: 24px;
			border-radius: 50%;
		}
	}
	.logout {
		cursor: pointer;
		margin-left: 16px;
	}
}
</style>
